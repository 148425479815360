<template>
  <div class="outDiv">
    <div class="info animated fadeInUp">
      <div class="info-top">
        <div class="name">
          <span>扫码录入</span>
          <el-input
            :class="imageShow ? '' : 'active'"
            v-model.trim="sn"
            autocomplete="off"
            placeholder="扫描要编辑的溯源码"
            maxlength="20"
            @blur="blurInput"
          ></el-input>
        </div>

        <div class="search-btn" @click="queryInfo">确定</div>
      </div>
      <div class="info-main">
        <div class="info-line">
          <div v-if="tranceData.length">
            <div
              class="line-items"
              v-for="(item, index) in tranceData"
              :key="item._id"
            >
              <div class="content-box">
                <img
                  class="portrait"
                  :title="item.photo ? '图片预览' : '暂无图片'"
                  :src="
                    item.photo
                      ? uploadImgs + item.photo
                      : require('../../assets/image/traceability/flowImgs.png')
                  "
                  @click="getPreview('img', item.photo)"
                />
                <div class="other-info">
                  <div class="col">
                    <span class="type">{{ item["流程"] }}</span>
                    <span class="time">{{ item["记录时间"] }}</span>
                    <span class="person"
                      >操作员：{{
                        item["操作员"] ? item["操作员"] : "无"
                      }}</span
                    >
                    <span
                      v-if="item.video"
                      class="my-video-icon el-icon-video-play"
                      title="视频预览"
                      @click="getPreview('video', item.video)"
                    ></span>
                    <!-- <span
                      class="my-video-icon el-icon-video-camera-solid"
                    ></span> -->
                  </div>
                  <!-- v-show="item.data.length" -->
                  <div class="col">
                    <div class="tags" v-for="(t, i) in item.data" :key="i">
                      <p class="tag-name">{{ i }}</p>
                      <p class="tag-main">{{ t }}</p>
                    </div>
                  </div>
                  <!-- <div class="col col-white" v-show="!item.data.length">
                    暂无流程
                  </div> -->
                </div>
              </div>
              <img
                class="del-icon"
                v-if="isDelShow !== index"
                src="../../assets/image/traceability/icon4.png"
                @mousemove="moveDel(index)"
              />
              <img
                class="del-icon"
                v-else
                src="../../assets/image/traceability/icon12.png"
                @mouseout="outDel"
                @click="delItems(index, item._id)"
              />
            </div>
          </div>
          <el-empty v-else description="暂无数据"></el-empty>
        </div>
        <MyPhone
          :isBoxShowNum="isBoxShowNum"
          :productInfo="productInfo"
          :batch_info="batch_info"
        ></MyPhone>
      </div>
    </div>
    <el-dialog :visible.sync="previewShow">
      <img width="100%" :src="videoUrl" v-show="pvType === 'img'" />
      <video
        width="100%"
        :src="videoUrl"
        v-show="pvType === 'video'"
        controls
        autoplay
      ></video>
    </el-dialog>
  </div>
</template>

<script>
import { getTranceSearchApi, delResourceItemApi } from "@/request/api";
import { mapState } from "vuex";

export default {
  components: {
    MyPhone: () => import("@/components/MyPhone.vue"),
  },
  data() {
    return {
      imageShow: true,
      previewShow: false,
      isDelShow: undefined,
      sn: undefined,
      isBoxShowNum: 7,
      productInfo: {},
      batch_info: {},
      tranceData: [],
      videoUrl: "",
      pvType: undefined,
    };
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      uploadImgs: (state) => state.app.uploadImgs,
      uploadFiles: (state) => state.app.uploadFiles,
    }),
  },

  methods: {
    getPreview(type, url) {
      this.pvType = type;
      if (type === "video") {
        if (url) {
          this.videoUrl = this.uploadFiles + "/" + url;
          this.previewShow = true;
        } else {
          this.previewShow = false;
        }
      } else {
        if (url) {
          this.videoUrl = this.uploadImgs + url;
          this.previewShow = true;
        } else {
          this.videoUrl = "../../assets/image/traceability/flowImgs.png";
          this.previewShow = false;
        }
      }
    },
    blurInput() {
      this.imageShow = this.sn ? true : false;
    },
    async queryInfo() {
      if (!this.sn) {
        this.imageShow = false;
        this.$notify.error({
          title: "错误",
          message: "请填写溯源码",
        });
        return;
      }
      const { code, results } = await getTranceSearchApi({
        SN: this.sn || undefined,
      });
      if (code === 0) {
        this.productInfo = results.product_info;
        this.batch_info = results.batch_info;
        this.tranceData = results.trance_data;
        this.tranceData.forEach((item) => {
          item.resourceValues = Object.values(item.resource);
          item.resourceKeys = Object.keys(item.resource);
          item["记录时间"] = item["记录时间"].substring(0, 10);
        });
      }
    },

    async delResourceItemApi(index, id) {
      const { code } = await delResourceItemApi({
        item_oid: id,
      });
      if (code === 0) {
        this.tranceData.splice(index, 1);
        this.$notify({
          title: "成功",
          message: "已删除",
          type: "success",
        });
      }
    },
    delItems(index, id) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delResourceItemApi(index, id);
        })
        .catch(() => {
          this.$notify({
            title: "提示",
            message: "已取消操作",
          });
        });
    },
    moveDel(index) {
      this.isDelShow = index;
    },
    outDel() {
      this.isDelShow = undefined;
    },
  },
};
</script>

<style lang="less" scoped>
.outDiv {
  padding: 0 48px;
  .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #0e2138;
    padding: 37px 49px;

    .info-top {
      width: 100%;
      height: 40px;
      margin-bottom: 42px;
      display: flex;
      align-items: center;

      .name {
        display: flex;
        align-items: center;
        position: relative;

        span {
          font-size: 14px;
          margin-right: 14px;
          color: #fff;
        }

        /deep/.el-input {
          width: 378px;
          margin-right: 52px;
          &.active {
            .el-input__inner {
              border-color: #f56c6c;
            }
          }
        }

        /deep/.el-input__inner {
          width: 378px;
          background-color: #081c30;
          border: 1px solid #385576;
          color: #57708d;
          font-size: 14px;
        }
      }

      .search-btn {
        width: 90px;
        height: 40px;
        background: #3e90e5;
        border-radius: 3px;
        font-size: 14px;
        color: #fff;
        margin-left: 10px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;

        &:hover {
          opacity: 0.9;
        }
      }
    }

    .info-main {
      width: 100%;
      display: flex;

      .info-line {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-left: 42px;

        .line-items {
          width: 1248px;
          min-height: 100px;
          background: #071a2c;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          margin-bottom: 44px;
          padding: 16px;

          &::before {
            content: "";
            width: 15px;
            height: 15px;
            background: #fff;
            border: 3px solid #3e90e5;
            border-radius: 50%;
            position: absolute;
            top: 12px;
            left: -42px;
          }

          &::after {
            content: "";
            position: absolute;
            top: 30px;
            left: -34px;
            width: 1px;
            height: calc(100% + 40px);
            border-right: 1px dashed #3e90e5;
          }

          &:last-of-type::after {
            display: none;
          }

          .content-box {
            display: flex;
            min-height: 65px;

            align-items: center;

            .portrait {
              width: 64px;
              height: 64px;
              margin-right: 26px;
              cursor: pointer;
            }

            .other-info {
              min-height: 65px;
              display: flex;
              flex-direction: column;
              justify-content: space-around;

              .col {
                width: 100%;
                min-height: 30px;

                display: flex;
                align-items: center;
                flex-wrap: wrap;
                margin-bottom: 19px;

                &:last-of-type {
                  margin-bottom: 0;
                }

                .type {
                  font-size: 18px;
                  color: #3e90e5;
                  margin-right: 26px;
                }

                .time,
                .person {
                  font-size: 14px;
                  color: #fff;
                  margin-right: 26px;
                }
                .my-video-icon {
                  color: #fff;
                  font-size: 22px;
                  cursor: pointer;
                }
                .tags {
                  display: flex;
                  align-items: center;
                  margin-right: 40px;
                  margin-bottom: 8px;

                  .tag-name {
                    min-width: 56px;
                    height: 24px;
                    line-height: 24px;
                    text-align: center;
                    background: #092139;
                    border: 1px solid rgba(62, 144, 229, 0.4);
                    border-radius: 3px;
                    font-size: 14px;
                    color: #3e90e5;
                    margin-right: 13px;
                    padding: 0 6px;
                  }

                  .tag-main {
                    font-size: 14px;
                    color: #fff;
                  }
                }
                &.col-white {
                  font-size: 14px;
                  color: #ccc;
                }
              }
            }
          }

          .del-icon {
            width: 37px;
            height: 37px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
